i svg {
  width: 24px;
  height: 24px;
}

#content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

#content h1 {
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 4px;
}

.social-links {
  display: flex;
  margin-top: 24px;
}